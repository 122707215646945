<template>
  <b-modal
    v-model="productItem.modal"
    :title="productItem.product"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    size="lg"
    centered
  >
    <swiper
      v-if="productItem.modal"
      class="swiper-navigations"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(img,index) in productItem.images"
        :key="index"
      >
        <b-img
          :src="productMediaURL + img.filename"
          fluid
          class="h-auto w-100 rounded"
        />
      </swiper-slide>
      <div
        slot="pagination"
        class="swiper-pagination"
      />
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper>
    <b-row class="mt-2">
      <b-col>
        <div class="text-center">
          <b-button
            v-if="!productItem.id_basket_items"
            class="mb-1"
            variant="primary"
            pill
            @click="addBasket(productItem)"
          >
            <FeatherIcon icon="ShoppingCartIcon" />
            {{ $t('Teklif Listeme Ekle') }}
          </b-button>
          <b-button
            v-else
            class="mb-1"
            variant="success"
            pill
            @click="removeItem(productItem.id_basket_items)"
          >
            <FeatherIcon icon="CheckCircleIcon" />
            {{ $t('Teklif Listeme Eklendi') }}
          </b-button>
        </div>
        <b-tabs
          pills
          fill
        >
          <b-tab active>
            <template #title>
              <feather-icon icon="InfoIcon" />
              <span>{{ $t('Ürün Bilgileri') }}</span>
            </template>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="font-weight-bold text-primary">
                  {{ $t('Ürün Kodu') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.product }}
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="font-weight-bold text-primary">
                  {{ $t('Kalite') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.product_quality }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.weight"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Gramaj') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.weight }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.width"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('En') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.width }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.width"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Konstrüksiyon') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.construction? productItem.construction : '-' }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.content"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Açıklama') }}:
                </div>
                <div class="font-weight-bold">
                  <div v-html="productItem.content" />
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.price"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Birim Fiyat') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.price | toCurrency }} {{ productItem.currency }}
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-tab>
          <b-tab v-if="productItem.tests.id">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>{{ $t('Ürün Test Sonuçları') }}</span>
            </template>
            <b-list-group>
              <b-list-group-item
                v-if="productItem.tests.asinma"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Martindale / Cycle') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.tests.asinma | toNumber }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.tests.asinma_sonuc"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Kopuş') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.tests.asinma_sonuc === '1'? $t('Var') : $t('Yok') }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.tests.pilling"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Pilling / Cycle') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.tests.pilling }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.tests.pilling_sonuc"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Pilling Değeri') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.tests.pilling_sonuc }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.tests.rh_kuru"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Renk Haslığı Değeri / Kuru') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.tests.rh_kuru }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.tests.rh_yas"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Renk Haslığı Değeri / Yaş') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.tests.rh_yas }}
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="productItem.tests.hav_kaybi"
                class="d-flex justify-content-between"
              >
                <div class="font-weight-bold text-primary">
                  {{ $t('Hav Kaybı Başlangıç Değeri') }}:
                </div>
                <div class="font-weight-bold">
                  {{ productItem.tests.hav_kaybi }}
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-tab>
        </b-tabs>
        <b-list-group class="mt-1">
          <b-list-group-item class="d-flex justify-content-center">
            <b-button
              :variant="productItem.like_it === '1'? 'success' : 'flat-success'"
              pill
              class="mx-1"
              :disabled="productItem.dislike_it === '1'"
              @click="likeItem"
            >
              <FeatherIcon
                icon="ThumbsUpIcon"
              /> {{ $t('Beğendim') }}
            </b-button>
            <b-button
              :variant="productItem.dislike_it === '1'? 'danger' : 'flat-danger'"
              pill
              class="mx-1"
              :disabled="productItem.like_it === '1'"
              @click="dislikeItem"
            >
              <FeatherIcon
                icon="ThumbsDownIcon"
              />
              {{ $t('Beğenmedim') }}
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  BImg, BModal, BRow, BCol, BButton, BListGroup, BListGroupItem, BTab, BTabs,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ProductModal',
  components: {
    BImg,
    BModal,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BTab,
    BTabs,
    Swiper,
    SwiperSlide,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalOpen: true,
      productMediaURL: this.$store.state.app.productMediaURL,
      swiperOptions: {
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    productItem() {
      return this.$store.getters['catalogs/productItem']
    },
  },
  methods: {
    addBasket(item) {
      this.$store.dispatch('baskets/addBasket', {
        quantity: 1,
        price: item.price,
        id_products: item.id_products,
        id_currencies: item.id_currencies,
        id_catalogs: item.id_catalogs,
        notes: null,
      })
        .then(response => {
          if (response.status) {
            this.productItem.id_basket_items = response.id
            this.showToast({
              title: 'İşlem Başarılı!',
              icon: 'CheckIcon',
              text: response.message,
              variant: 'success',
            })
            this.$store.dispatch('baskets/getData')
          } else {
            this.showToast({
              title: 'İşlem Hatası!',
              icon: 'XIcon',
              text: response.message,
              variant: 'danger',
            })
          }
        })
    },
    removeItem(id) {
      this.$store.dispatch('baskets/removeItem', {
        id,
      })
        .then(response => {
          if (response.status) {
            this.productItem.id_basket_items = null
            this.showToast({
              title: 'İşlem Başarılı!',
              icon: 'CheckIcon',
              text: response.message,
              variant: 'success',
            })
            this.$store.dispatch('baskets/getData')
          } else {
            this.showToast({
              title: 'İşlem Hatası!',
              icon: 'XIcon',
              text: response.message,
              variant: 'danger',
            })
          }
        })
    },
    likeItem() {
      this.$store.dispatch('catalogs/interactions', {
        id: this.productItem.id,
        like_it: this.productItem.like_it !== '1',
      })
        .then(response => {
          if (response) {
            this.productItem.like_it = this.productItem.like_it === '1' ? '0' : '1'
            this.productItem.dislike_it = '0'
            this.refreshData()
          }
        })
    },
    dislikeItem() {
      this.$store.dispatch('catalogs/interactions', {
        id: this.productItem.id,
        dislike_it: this.productItem.dislike_it === '1' ? '0' : '1',
      })
        .then(response => {
          if (response) {
            this.productItem.like_it = '0'
            this.productItem.dislike_it = this.productItem.dislike_it === '1' ? '0' : '1'
            this.refreshData()
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
<style>
.swiper-button-prev,
.swiper-button-next{
    background: var(--primary);
    color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
}
.swiper-button-disabled{
    opacity: 0.2;
}
.swiper-button-prev:after,
.swiper-button-next:after{
    font-size: 12px;
}
</style>
